import React from 'react';
// import {PaginationWrap, PaginationList, PaginationItem, PaginationLink} from './pagination.stc'
import Swiper from 'react-id-swiper';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import RealCube from 'react-cube3d-component';

import './styles.css';
const Cube = () => {
  const params = {
    effect: 'cube',
    grabCursor: true,
    cubeEffect: {
      shadow: true,
      slideShadows: true,
      shadowOffset: 20,
      shadowScale: 0.94,
    },
    pagination: {
      el: '.swiper-pagination',
    },
  };

  // const cubeQueryData = useStaticQuery(graphql`
  //   query cubeQueryDataQuery {
  //     homecubedataJson(id: { eq: "vial" }) {
  //       id
  //       title
  //       image {
  //         childImageSharp {
  //           fluid(maxWidth: 500, maxHeight: 850, quality: 100) {
  //             ...GatsbyImageSharpFluid_withWebp
  //             presentationWidth
  //             presentationHeight
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);
  // const cubeData = cubeQueryData.homecubedataJson;
  // const { title } = cubeData;
  // const imageData = cubeData.image.childImageSharp.fluid;

  return (
    <div>
      <section className='three-d-container' style={{ zIndex: 99999 }}>
        <input type='radio' className='three-d-bullet a' name='three-d' />
        <input type='radio' className='three-d-bullet b' name='three-d' />
        <input type='radio' className='three-d-bullet c' name='three-d' />
        <input type='radio' className='three-d-bullet d' name='three-d' />

        <div className='three-d-cube'>
          <figure className='three-d-item'>
            <Link to={`/area/vial`}>
              {' '}
              <img
                style={{ width: '500px', background: 'white' }}
                src='../../../assets/vial.png'
                alt=''
              />
            </Link>
          </figure>

          <figure className='three-d-item'>
            <Link to={`/area/detalle`}>
              <img
                style={{ width: '500px', background: 'white' }}
                src='https://mhodev.codyware.com/assets/detalle.png'
                alt=''
              />
            </Link>
          </figure>
          <figure className='three-d-item'>
            <Link to={`/area/ambiental`}>
              <img
                style={{ width: '500px', background: 'white' }}
                src='https://mhodev.codyware.com/assets/ambiental.png'
                alt=''
              />
            </Link>
          </figure>
          <figure className='three-d-item'>
            <Link to={`/area/geomesura`}>
              <img
                style={{ width: '500px', background: 'white' }}
                src='https://mhodev.codyware.com/assets/geomesura.png'
                alt=''
              />
            </Link>
          </figure>
          <figure className='three-d-item'>
            <img
              style={{ width: '500px', background: 'white' }}
              src='https://mhodev.codyware.com/assets/mho.png'
              alt=''
            />
          </figure>
          <figure className='three-d-item'>
            <img
              style={{ width: '500px', background: 'white' }}
              src='https://mhodev.codyware.com/assets/mho.png'
              alt=''
            />
          </figure>
        </div>
      </section>
    </div>
  );
};

export default Cube;
