import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import SectionTitle from '../../../components/shared/section-title';
import Area from '../../../components/area';
import Cube from '../../../components/services/cube';
import { ProjectSectionWrap } from './area.stc';

const AreaSection = ({ section, project }) => {
  const projectQueryData = useStaticQuery(graphql`
    query VideoAreaDataQuery {
      homevideodataJson(id: { eq: "video_area_section_content" }) {
        id
        title
        subtitle
      }
      allAreasJson(filter: { is_featured: { eq: true } }, limit: 4) {
        edges {
          node {
            id
            title
            sector
            client
            color
            featured_image {
              childImageSharp {
                fluid(maxWidth: 1060, maxHeight: 600, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
        }
      }
    }
  `);
  const projectSecData = projectQueryData.homevideodataJson;
  const projectsData = projectQueryData.allAreasJson.edges;
  return (
    <ProjectSectionWrap {...section}>
      <div className='project-container'>
        <div className='col-1 offset-1'>
          <SectionTitle
            title={projectSecData.title}
            subtitle={projectSecData.subtitle}
            color={projectSecData.color}
          />
        </div>

        <Cube />
        {/* <div className='project-wrapper'>
          {projectsData.map((data, i) => {
            let isEven = i % 2 === 0;
            return (
              <Area
                {...project}
                isEven={isEven}
                key={data.node.id}
                id={data.node.id}
                image={data.node.featured_image.childImageSharp}
                title={data.node.title}
                client={data.node.client}
                sector={data.node.sector}
                color={data.node.color}
              />
            );
          })}
        </div> */}
      </div>
    </ProjectSectionWrap>
  );
};

AreaSection.propTypes = {
  section: PropTypes.object,
  project: PropTypes.object,
};

AreaSection.defaultProps = {
  section: {
    backgroundColor: '#f8f8f8',
  },
  project: {
    mt: '100px',
    responsive: {
      medium: {
        mt: '50px',
      },
    },
  },
};

export default AreaSection;
