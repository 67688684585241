import styled, { css } from 'styled-components';
import { device } from '../../theme';

export const NotificationWrap = styled.div`
  background: #ee801ce8;
  position: fixed;
  bottom: 10px;
  z-index: 99;
  right: 18px;
  color: white;

  display: ${(props) => props.display};
  flex-direction: column;
  @media ${device.medium} {
    padding-bottom: 23px;
  }
  @media ${device.small} {
    padding-bottom: 23px;
  }
`;
export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: initial;
  padding: 21px;
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 40px 39px 40px;
  align-items: center;
`;
