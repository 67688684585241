import React, { useState } from 'react';
import {
  NotificationWrap,
  HeaderWrapper,
  ContentWrapper,
} from './notification.stc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopHouse, faTimes } from '@fortawesome/free-solid-svg-icons';
import './style.css';

const Notification = () => {
  const [isClosed, setIsClosed] = useState(false);
  const [display, setDisplay] = useState('flex');
  let handleClose = () => {
    setDisplay('none');
    setIsClosed(true);
  };

  return (
    <NotificationWrap display={display}>
      <HeaderWrapper>
        <FontAwesomeIcon
          id='loading'
          icon={faTimes}
          style={{ fontSize: '24px' }}
          className='loading-arrow'
          onClick={handleClose}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <FontAwesomeIcon
          id='loading'
          icon={faLaptopHouse}
          style={{ fontSize: '44px', marginBottom: '15px' }}
          className='loading-arrow'
        />
        por nuestra seguridad seguimos trabajando en casa
      </ContentWrapper>
    </NotificationWrap>
  );
};

export default Notification;
